/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for support.userx.co
 */

import {createContext, useContext} from "react";
import useLocalStorage from "../_hooks/useLocalStorage";
import supportAPI from "../_apis/supportAPI";
import {addNotification} from "../_utilities/notificationUtilities";
import {sendNote} from "../_utilities/contactUtilities";
import {addActivity} from "../_utilities/activityUtilities";

export const UserContext = createContext();

export const UserProvider = ({children}) => {
    const [userContext, setUserContext] = useLocalStorage('uxUser', {
        isLoggedIn: false,
        contact:    null,
        dashboard:  null,
        filters:    []
    });

    const hasAccess = () => {

    }



    return (
        <UserContext.Provider value={{userContext, setUserContext}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);