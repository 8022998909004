/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import React, {useContext, useEffect, useRef, useState} from 'react';
import StatBlock from "./_ui/StatBlock";
import {formatCurrency} from "../_utilities/formattingUtils";
import {useUser} from "../_contexts/UserContext";
import {search} from "../_utilities/reactUtils";
import {useParams} from "react-router";
import TableBlock from "./_ui/TableBlock";
import supportAPI from "../_apis/supportAPI";
import {STEP, STEP_VALUE} from "../system/defaults";
import useInterval from "../_hooks/useInterval";

const AgentDashboard = () => {
    const params = useParams();
    const {userContext} = useUser();
    const agentId = params.agentId ? params.agentId : userContext.contact ? userContext.contact.contact_id : null;
    const agentStepValue = calculateAgentStepValue(userContext.contact.meta.find(m => m.meta_key === 'pay_rate'));
    const ticketTotal = useRef(0);
    const overdueTotal = useRef(0);
    const monthTotal = useRef(0);
    const ytdTotal = useRef(0);
    const [myAssigned, setMyAssigned] = useState([]);
    const [allowed, setAllowed] = useState(true);

    useEffect(() => {
        console.log('userContext',userContext);
        refresh()
    }, []);

    useInterval(() => {
        refresh();
    }, 5000);

    return (
        <div className={`d-flex flex-column h-100`}>
            <div className={`d-flex border-bottom flex-wrap`}>
                <StatBlock title={`Total`} number={ticketTotal.current}
                           classes={`border-end border-bottom bg-secondary text-white`}
                           style={{minWidth: '150px'}}/>
                <StatBlock title={`Overdue`} number={overdueTotal.current}
                           classes={`border-end border-bottom bg-secondary text-white`}
                           style={{minWidth: '150px'}}/>
                <StatBlock title={`${new Date().toLocaleString("default", {month: "long"})} Income`}
                           number={formatCurrency(monthTotal.current)}
                           classes={`border-end border-bottom bg-secondary text-white`} style={{minWidth: '150px'}}/>
                <StatBlock title={`YTD Income`} number={formatCurrency(ytdTotal.current)}
                           classes={` border-bottom bg-secondary text-white`}
                           style={{minWidth: '150px'}}/>
            </div>
            <div className={`flex-grow-1 align-items-stretch p-3 m-3`}>
                <TableBlock title={`My Assigned Tickets`} classes={`table-mode bg-white`}
                            items={myAssigned}/>
            </div>
        </div>
    );

    function calculateAgentStepValue(rateMeta) {
        if (rateMeta !== undefined) {
            return parseFloat(rateMeta.meta_value) / (60 / STEP);
        }
        return 0;
    }

    function refresh() {
        if (agentId) {
            // allowed to view page
            setAllowed(() => true);
            const agentId = params.agentId ? params.agentId : userContext.contact.contact_id;
            search('ticket', [
                `assignee_id = ${agentId}`,
                `(status_id <= 4 || status_id >= 7)`
            ], 'AND', 'priority_id ASC, date_due ASC').then(response => {
                setMyAssigned(()=>response);
            });
        } else {
            setAllowed(() => false);
        }

        // update stats
        ticketTotal.current = myAssigned.length;

        const currentDate = new Date();
        currentDate.setHours(0,0,0,0);
        overdueTotal.current = myAssigned.filter(t => {
            const dueDate = new Date(t.date_due);
            dueDate.setHours(0,0,0,0);
            return dueDate < currentDate;
        }).length;

        supportAPI.get(`/contact/${agentId}/currenttime`).then(response => {
            monthTotal.current = response.data.total_time ? parseInt(response.data.total_time.toString()) * agentStepValue : 0;
        });
        supportAPI.get(`/contact/${agentId}/ytdtime`).then(response => {
            ytdTotal.current = response.data.total_time ? parseInt(response.data.total_time.toString()) * agentStepValue : 0;
        });
    }
};

export default AgentDashboard;