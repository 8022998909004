/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/7/2022
 * for support.userx.co
 */

import {STEP_VALUE} from "../system/defaults";
import supportAPI from "../_apis/supportAPI";
import {convertStepsToTime, formatCurrency} from "./formattingUtils";
import {addActivity} from "./activityUtilities";
import {search} from "./reactUtils";
import {fetchContact, sendActivity, sendNewTicket, sendNote} from "./contactUtilities";
import {addNotification} from "./notificationUtilities";
import {tick} from "@apollo/client/testing";

export const addTicket = async (ticket) => {
    console.log('ticketUtils::addTicket',ticket);
    const ticketResponse = await supportAPI.post(`/ticket`, JSON.stringify(ticket));
    if (!ticketResponse.data) {
        throw new Error('Ticket not created.');
    }
    await sendNewTicket(ticketResponse.data)
    return ticketResponse.data;
}

export const assignTicket = async (ticket, agent) => {
    const ticketData = {
        ticket_id: ticket.ticket_id,
        assignee_id: agent.contact_id,
        status_id: "2"
    };

    try {
        // Update the ticket with the new assignee
        const ticketResponse = await supportAPI.put(`/ticket/${ticket.ticket_id}`, JSON.stringify(ticketData));
        console.log('Ticket updated:', ticketResponse.data);

        // Send notification
        await addNotification({
            contact_id: agent.contact_id,
            ticket_id: ticket.ticket_id,
            note: `You have been assigned to ticket ${ticket.ticket_id}.`
        });
        console.log('Notification sent to agent', agent);

        // Send an email notification
        await sendNote(agent.contact_id, `User X Support: Assigned Task`, `You have been assigned to ticket ${ticket.ticket_id}.`);
        console.log('Email sent to agent', agent);

        // Add activity log
        await addActivity({
            ticket_id: ticket.ticket_id,
            contact_id: agent.contact_id,
            note: `Assigned ${agent ? `${agent.first_name} ${agent.last_name}.` : `an agent.`}`
        });
        console.log('Activity logged for ticket assignment.');

    } catch (error) {
        console.error('Failed to assign ticket or send notifications:', error);
    }
};

export const fetchTicket = async (ticket_id) => {
    const ticketResponse = await supportAPI.get(`/ticket/${ticket_id}`);
    if (!ticketResponse.data) {
        throw new Error('Ticket not found.')
    }
    return ticketResponse.data;
}

export const fetchTicketList = async (currentUser, filter = null) => {
    if (!filter && currentUser.contact.group_id !== '1') {
        throw new Error('Not authorized to access complete ticket list.');
    }
    let ticketResponse;
    if (!filter) {
        ticketResponse = await supportAPI.get(`/ticket`);
    } else {
        ticketResponse = await supportAPI.post(`/search/ticket`, filter ? JSON.stringify(filter) : null);
    }
    if (!ticketResponse.data) {
        throw new Error('No tickets found.')
    }
    return ticketResponse.data;
}

export const fetchTicketActivity = async (ticket_id) => {
    if (!ticket_id || ticket_id <= 0) {
        throw new Error('Input out of range.');
    }

    try {
        const activityResponse = await supportAPI.get(`/ticket/${ticket_id}/activity`);
        // const activityResponse = await search('activity', [`ticket_id=${ticket_id}`], 'AND', 'date_created DESC');
        return activityResponse.data;
    } catch (err) {
        console.error('FAIL', err);
    }

    return [];

}

export const updateTicketField = async (ticket_id, field, value) => {
    if (!field) {
        throw new Error('Invalid input.');
    }
    const data = {};
    data[field] = value;
    const ticketResponse = await supportAPI.put(`/ticket/${ticket_id}`, JSON.stringify(data));
    if (!ticketResponse.data) {
        throw new Error('Ticket not updated.');
    }
    return ticketResponse.data;
}

export const addTime = async (time) => {
    console.log('ticketUtilities::addTime::time',time);
    if ((!time || typeof time !== 'object') || (!time.contact_id || !time.time || (!time.description || time.description === ''))) {
        throw new Error('Invalid input. Make sure your description is proper and you have time set.');
    }

    try {
        const timeResponse = await supportAPI.post(`/time`, JSON.stringify(time));
        if (!timeResponse.data) {
            throw new Error('Time not entered.');
        }

        let activityNote = `${convertStepsToTime(time.time)} added.`;
        const contactResponse = await supportAPI.get(`/contact/${time.contact_id}`);
        if (contactResponse.data) {
            activityNote = `${contactResponse.data.first_name} ${contactResponse.data.last_name} has entered ${convertStepsToTime(time.time)} - ${time.description}`
        }

        try {
            await addActivity({
                ticket_id:  time.ticket_id,
                contact_id: time.contact_id,
                note:       activityNote
            });
        } catch (err) {
            console.error('FAIL', err);
        }

        return timeResponse.data;
    } catch(err){
        console.error('ticketUtils::addTime::FAIL',err);
        throw err;
    }
}

export const calculateTicketCost = async (ticket, raw = false) => {
    if (!ticket) throw new Error('Invalid input.');
    const ticketTimeResponse = await supportAPI.get(`/ticket/${ticket.ticket_id}/time`);
    if (!ticketTimeResponse.data || !ticketTimeResponse.data.total_time) {
        if(raw) return 0;
        return "$0";
    }
    if (raw) return parseInt(ticketTimeResponse.data.total_time) * STEP_VALUE;
    return formatCurrency(parseInt(ticketTimeResponse.data.total_time) * STEP_VALUE);
}

/**
 *
 * @param currentUser
 * @param raw
 * @returns {Promise<string|number>}
 *
 * @todo Figure out how to filter by month
 */
export const calculateMonthCost = async (currentUser, raw = false) => {
    if (!currentUser) return '$0.00';
    let total = 0;
    const tickets = await supportAPI.post(`/search/ticket`, JSON.stringify({contact_id: 1}));
    console.log('tickets', tickets);
    if (tickets.data) {
        tickets.data.forEach(ticket => {
            total += calculateTicketCost(ticket, true);
        });
    }

    if (raw) return total;

    return formatCurrency(total);
}

export const countUnassigned = (ticketList) => {
    return ticketList.filter(ticket => ticket.status_id === "1").length
}

export const calculateListMonthCost = async (contact_id) => {
    console.log('ticketUtils::calculateListMonthCost', contact_id);
    const response = await search('time', [`contact_id=${contact_id}`, `MONTH(date)=MONTH(now())`, `YEAR(date)=YEAR(now())`]);
    const totalTime = response.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.time);
    }, 0);
    return formatCurrency(totalTime * STEP_VALUE);
}

export const calculateListYtdCost = async (contact_id) => {
    const response = await search('time', [`contact_id=${contact_id}`, `YEAR(date)=YEAR(now())`]);
    const totalTime = response.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.time);
    }, 0);
    return formatCurrency(totalTime * STEP_VALUE);
}