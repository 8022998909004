/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 1/2/2023
 * for userx-support
 */

import React, {useEffect, useRef, useState} from 'react';

export default function useInterval(callback, delay = 1000, isActive = true) {
    const savedCallback = useRef();
    const [isTabActive, setIsTabActive] = useState(!document.hidden);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Track tab visibility
    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsTabActive(!document.hidden);
        }
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => document.removeEventListener('visibilitychange',handleVisibilityChange);
    }, []);

    // Set up the interval.
    useEffect(() => {
        if (delay !== null && isActive && isTabActive) {
            tick();
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay, isActive, isTabActive]);

    function tick() {
        try {
            savedCallback.current();
        } catch(err) {
            console.error('Error during interval execution',err.message);
        }
    }
}