/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/25/2022
 * for support.userx.co
 */

import React, {useEffect, useState} from 'react';
import {fetchCompany, fetchCompanyContacts} from "../../_utilities/companyUtilities";
import {fetchContact} from "../../_utilities/contactUtilities";
import {useNavigate} from "react-router";
import {useUser} from "../../_contexts/UserContext";
import ReactMarkdown from "react-markdown";

const CompanyInfo = ({company_id, contact_id}) => {
    const navigate = useNavigate();
    const {userContext} = useUser();
    const [company, setCompany] = useState(null);
    const [contact, setContact] = useState(null);
    const [showNote, setShowNote] = useState(false);

    useEffect(() => {
        if (company_id) {
            fetchCompany(company_id).then(c => setCompany(() => c));
            fetchContact(contact_id).then(cc => setContact(() => cc));
        }
    }, [company_id]);

    return (<>
        {company || contact ? (
            <div className='bg-white p-3 mb-3 rounded shadow-sm'>
                {company ? <div className=' d-flex justify-content-between'>
                    {userContext.contact.group_id === "1"
                        ? <h5 className='m-0'><a href={`/clients/${company_id}`} title='Company Dashboard'>{company.name}</a></h5>
                        : <h5 className='m-0'>{company.name}</h5>}
                    {company.note && parseInt(userContext.contact.group_id) <= 4 ?
                        showNote
                        ? <i title={`Hide Note`} className='bi bi-info-square-fill' onClick={()=>setShowNote(()=> !showNote)}/>
                        : <i title={`Show Note`} className='bi bi-info-square' onClick={()=>setShowNote(()=> !showNote)}/> : null}
                    </div> : null}
                <hr className='my-2'/>
                {showNote ? <div id={`company_notes`} className={`hidden`}>
                    <ReactMarkdown>{`${company.note}`}</ReactMarkdown>
                    <hr className='my-2'/>
                </div> : null }
                {contact && contact.email ? <div className='m-0 d-flex justify-content-between'>
                    <strong>{contact.first_name} {contact.last_name}</strong> <a href={`mailto:${contact.email}`} target='_blank' title='Email'><i
                    className='bi bi-envelope-plus'/></a>
                </div> : null}
                {company && company.url ? <div className='m-0 d-flex justify-content-between'>
                    {company.url.toLowerCase()} <a href={company.url} target='_blank' title='Visit Site'><i
                    className='bi bi-box-arrow-up-right'/></a>
                </div> : null}
                {contact && contact.phone ?
                    <div className='m-0 d-flex justify-content-between'>
                        {contact.phone} <a href={`tel:${contact.phone}`} target='_blank' title='Email' title='Call'><i
                        className='bi bi-telephone-outbound'/></a>
                    </div> : null}
            </div>
        ) : null}
    </>);

    function showNotes(){
        console.log('CompanyInfo::showNotes', company.note);
    }
}

export default CompanyInfo;