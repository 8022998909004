/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 4/23/2023
 * for userx-support
 */

import React, {useEffect, useState} from 'react';
import Checkbox from "../_ui/formElements/Checkbox";
import supportAPI from "../../_apis/supportAPI";
import useInterval from "../../_hooks/useInterval";
import {convertDateToTimestamp} from "../../_utilities/formattingUtils";
import TicketTask from "./TicketTask";
import {generateTaskList, simpleTest} from "../../_apis/openChatAPI";

const TicketTasks = ({ticket, classes}) => {
    const [tasks, setTasks] = useState([]);
    const [addText, setAddText] = useState("");
    const [showAddTask, setShowAddTask] = useState(false);
    const [hideCompleted, setHideCompleted] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isGeneratingTasks, setIsGeneratingTasks] = useState(false);

    useEffect(refresh, []);
    useInterval(refresh, 1000);

    const handleGeneratingTasks = () => {
        setIsGeneratingTasks(true); // Set to true when the generation starts
        generateTaskList(ticket.title, ticket.description)
        .then((tasks) => {
            console.log("TicketTasks::generateTaskList", tasks);
            // Iterate over each task and post to the API
            tasks.forEach(async (task) => {
                try {
                    await supportAPI.post(`/ticket/${ticket.ticket_id}/tasks`, JSON.stringify({
                        ticket_id: ticket.ticket_id,
                        instruction: task // Using taskDescription as the task
                    }));
                } catch (error) {
                    console.error('Error adding task:', error);
                }
            });
        })
        .catch((err) => {
            console.error("TicketTasks::generateTaskList::ERROR", err);
        })
        .finally(() => {
            setIsGeneratingTasks(false); // Set back to false when task generation completes
        });
    }

    return (
        <div id='ticket-tasks' className={classes}>
            <h4 className={`d-flex justify-content-between align-items-center`}>
                <span onClick={toggleVisibility}><i className='bi bi-check2-square'/> Tasks </span>
                <div className={`d-flex justify-content-end`}>
                    <div className={`d-flex`}>
                        {tasks && tasks.length > 0 ? hideCompleted ?
                            <i className={`bi bi-eye me-3`} title={`Show Completed Tasks`} style={{marginTop: "4px"}}
                               onClick={() => {
                                   setHideCompleted(false)
                               }}/>
                            :
                            <i className={`bi bi-eye-slash me-3`} title={`Hide Completed Tasks`}
                               style={{marginTop: "4px"}}
                               onClick={() => {
                                   setHideCompleted(true)
                               }}/> : null
                        }
                        <div id='file-attachment-add'>
                            <i className='bi bi-plus-circle-fill text-success' style={{fontSize: '30px'}}
                               onClick={() => setShowAddTask(() => !showAddTask)}/>
                            {showAddTask ? <div id='file-attachment-tool' className=' bg-white shadow-lg p-3 rounded'>
                                <i className='bi bi-x-circle-fill text-secondary'
                                   style={{fontSize: '30px', position: 'absolute', top: '0', right: '5px'}}
                                   onClick={() => setShowAddTask(() => !showAddTask)}/>
                                <strong>Add Task</strong>
                                <form className={`task-list-add`} onSubmit={e => {
                                    e.preventDefault();
                                    addTask(addText)
                                }}>
                                    <input type='text' name='task-instruction' value={addText}
                                           className={`form-control w-100 mb-1`}
                                           onChange={e => setAddText(e.target.value)}/>
                                    <input type='submit' className={`btn btn-success btn-sm float-end`}
                                           value='Add Task'/>
                                </form>
                            </div> : null}

                        </div>

                        <i
                            className={`bi ${isGeneratingTasks ? 'bi-cloud-arrow-down-fill' : 'bi-cpu-fill'} cursor-pointer text-success mx-3`}
                            title={isGeneratingTasks ? `Generating tasks...` : `Auto-generate Tasks`}
                            style={{fontSize: '30px'}}
                            onClick={!isGeneratingTasks ? handleGeneratingTasks : null}/>
                    </div>
                </div>

            </h4>
            <div className={`task-list`}>
                {tasks && tasks.filter ? tasks.filter(task => (hideCompleted && !task.date_completed) || (!hideCompleted)).map(task =>
                    <TicketTask key={`task-${task.task_id}`}
                                task={task}
                                name={`task-${task.task_id}`}
                                checked={task.date_completed ? true : false}
                                label={task.date_completed ?
                                    <del>{task.instruction}</del> : task.instruction}
                                changeHandler={active => taskChangeHandler(task.task_id, active)}
                                editHandler={value => taskUpdateHandler(task.task_id, value)}
                                deleteHandler={del => taskDeleteHandler(task.task_id, del)}
                                classes={`mb-2`}
                    />) : null}
            </div>

        </div>
    );

    function toggleVisibility() {

    }

    function addTask() {
        console.log('TicketTasks::addTask',ticket);
        supportAPI.post(`/ticket/${ticket.ticket_id}/tasks`, JSON.stringify({
            ticket_id:   ticket.ticket_id,
            instruction: addText
        })).then(response => {
            console.log('TicketTasks::addTask::response',response);
            setAddText(() => '');
        });
    }

    function taskChangeHandler(task_id, active) {
        console.log('TicketTasks::taskChangeHandler', task_id, active);
        const task = tasks.find(task => task.task_id === task_id);
        supportAPI.put(`/ticket/${ticket.ticket_id}/tasks/${task_id}`, JSON.stringify({
            ...task,
            date_completed: active ? convertDateToTimestamp(new Date()) : 'null'
        })).then((response) => console.log('TicketTasks::taskChangeHandler', response));
    }

    function taskUpdateHandler(task_id, instruction) {
        console.log('TicketTasks::taskUpdateHandler', task_id, instruction);
        const task = tasks.find(task => task.task_id === task_id);
        supportAPI.put(`/ticket/${ticket.ticket_id}/tasks/${task_id}`, JSON.stringify({
            ...task,
            instruction: instruction
        }));
    }

    function taskDeleteHandler(task_id, confirmed) {
        console.log('TicketTasks::taskDeleteHandler', task_id, confirmed);
        if (confirmed) {
            const task = tasks.find(task => task.task_id === task_id);
            supportAPI.delete(`/ticket/${ticket.ticket_id}/tasks/${task_id}`);
        }
    }

    function refresh() {
        try {
            supportAPI.get(`/ticket/${ticket.ticket_id}/tasks`).then(response => {
                    if (response.data) {
                        setTasks(() => response.data);
                    } else {
                        setTasks(() => []);
                    }
                }
            );
        } catch (err) {
            console.error('FAIL', err);
        }
    }
};

export default TicketTasks;