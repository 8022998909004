/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for support.userx.co
 */

import React, {useContext, useState} from 'react';
import {STEP_VALUE, TICKET_PRIORITIES, TICKET_STATUS, TICKET_TYPES} from "../../system/defaults";
import Badge from "react-bootstrap/Badge";
import {formatCurrency, formatDate, abbreviateString} from "../../_utilities/formattingUtils";
import {useNavigate} from "react-router";
import {UserContext} from "../../_contexts/UserContext";

const TicketItem = ({ticket, showIcon, onSelect = null}) => {
    const navigate = useNavigate();
    const LONG_PRESS_DURATION = 500;
    const [pressTimer, setPressTimer] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const priority = TICKET_PRIORITIES.find(i => i.id === parseInt(ticket.priority_id));
    const status = TICKET_STATUS.find(i => i.id === parseInt(ticket.status_id));
    const type = TICKET_TYPES.find(i => i.id === parseInt(ticket.type_id));
    const estimate = {
        required: parseInt(ticket.estimate_required),
        time: parseInt(ticket.estimated_time),
        approved: parseInt(ticket.estimate_approved)
    }

    const handleMouseDown = () => {
        setLongPressTriggered(false);
        const timer = setTimeout(()=>{
            setLongPressTriggered(true);
            setIsSelected(prev => {
                const newState = !prev;
                if (onSelect) {
                    onSelect(ticket.ticket_id, newState);
                }
                return newState;
            });
        }, LONG_PRESS_DURATION);
        setPressTimer(timer);
    }

    const handleMouseUp = () => {
        // Clear the timer if mouse is released before the timer runs out
        clearTimeout(pressTimer);
        setPressTimer(null);
        if(!longPressTriggered && !isSelected){
            console.log('TicketItem::clickHandler',ticket);
            navigate(`/clients/${ticket.company_id}/tickets/${ticket.ticket_id}`)
        }
    };

    const handleMouseLeave = () => {
        // Clear the timer if mouse leaves the element
        clearTimeout(pressTimer);
        setPressTimer(null);
    };

    return (
        <div
            className={`list-item bg-white rounded mb-3 p-1 d-flex align-items-start ${ticket.status_id === 6 || ticket.on_hold > 0 ? `opacity-50` : null} ${isSelected ? 'selected' : ''}`}
            id={ticket.ticket_id}
            key={ticket.ticket_id}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}>
            {showIcon ? <div className={`ticket-icon`} title={ticket ? ticket.company_name : '?'} style={{
                background: "#999",
                color: "#fff",
                borderRadius: "50%",
                lineHeight: "3em",
                textAlign: "center",
                flex:"0 0 3em",
                height:"3em",
                marginRight:".5em"
            }}>{abbreviateString(ticket ? ticket.company_name : '?')}</div>
                : null }
            <div className={``}><span>
                    {priority && status ?
                        <Badge className='me-1' bg={priority.color} title={priority.name}>{status.name}</Badge> : null}
                {type ? <Badge bg="secondary" title="type">{type.name}</Badge> : null}

                {estimate.required ? <>
                    {estimate.time === 0 ?
                        <Badge bg="transparent" style={{color: 'red'}} title="estimate">Estimate</Badge> : <>
                            {estimate.approved ? <Badge bg="transparent" style={{color: 'green'}}
                                                        title="estimate">{formatCurrency(ticket.estimated_time * STEP_VALUE)}</Badge> :
                                <Badge bg="transparent" style={{color: 'red'}} title="estimate">Approval</Badge>}
                        </>}
                </> : null}

                </span>
                <span>
                    <strong className="px-1">{ticket.title}</strong>
                    <div className='text-nowrap' style={{fontSize:"11px"}}>Submitted: {formatDate(ticket.date_created)} - Due: {formatDate(ticket.date_due)}</div>
                </span>
            </div>
        </div>
    );
};

export default TicketItem;